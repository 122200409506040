$color-green-darken: darken(#b8e986, 10%);
$color-green-darken-2x: darken(#b8e986, 30%);
$color-red-dark: #721c24;
$color-yellow-dark: #CEAC09;

.check-ok {
  color: $color-green-darken;
}

.check-danger {
  color: $color-red-dark;
}

tr.registration-in-progress > td {
  color: $color-yellow !important;
}

.text-yellow {
  color: $color-yellow-dark;
}

.text-red {
  color: $color-red;
}

.text-green {
  color: $color-green-darken-2x;
}